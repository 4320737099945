import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

document.addEventListener("keydown", (e) => {
  if (e.keyCode === 71 && e.shiftKey === true) {
    document.querySelector(".grid-overlay").classList.toggle("show");
  }
});

$(document).ready(function () {
  // $(".open-image").click(function () {
  //   $("html").addClass("no-scroll");
  //   var clicked = $(this);
  //   $('<div class="full-screen-image"><a href="#" class="close-fullscreen-image"><svg class="icon icon-close"><use xlink:href="#icon-close"></use></svg></a></div>').insertAfter("main");
  //   var container = $(".full-screen-image");
  //   var image = $(this).find(".media-block");
  //   container.append(image.clone());
  //   var width = clicked.width(),
  //     height = clicked.height(),
  //     top = clicked.offset().top - $(window).scrollTop(),
  //     left = clicked.offset().left;
  //   if (clicked.hasClass("light")) {
  //     container.addClass("light");
  //   }
  //   container[0].style.setProperty("--start-width", `${width}px`);
  //   container[0].style.setProperty("--start-height", `${height}px`);
  //   container[0].style.setProperty("--start-top", `${top}px`);
  //   container[0].style.setProperty("--start-left", `${left}px`);
  //   container
  //     .css({
  //       width: width,
  //       height: height,
  //       top: top,
  //       left: left,
  //       position: "fixed",
  //       "z-index": 50,
  //     })
  //     .addClass("animate");
  //   clicked.addClass("open");
  //   return false;
  // });

  // $("body").on("click", ".close-fullscreen-image", function () {
  //   $(".full-screen-image").addClass("close");
  //   setTimeout(function () {
  //     $(".full-screen-image").remove();
  //     $(".open-image").removeClass("open");
  //   }, 600);
  //   $("html").removeClass("no-scroll");
  //   return false;
  // });

  $(".js-confirm-age").click(function () {
    $("html").removeClass("no-scroll");
    $("body").removeClass("modal-open");
    Cookies.set("age_confirmed", "true", { sameSite: "strict" });
    $(".confirm-age-wrap").animate({ opacity: "0" }, 200);
    setTimeout(function () {
      $(".confirm-age-wrap").removeClass("show");
      $(".confirm-age-wrap").remove();
    }, 600);
    return false;
  });
  $(".js-reject-age").click(function () {
    // $(".confirm-age-wrap").addClass("rejected");
    history.back();
    return false;
  });

  $(".open-modal").click(function () {
    var modalClass = $(this).attr("data-modal");
    $("." + modalClass).addClass("show");
    $("html").addClass("no-scroll");
    return false;
  });
  $(".close-modal, .modal-wrap:not(.confirm-age-wrap) .modal-bg").click(function () {
    $(this).parents(".modal-wrap").removeClass("show");
    $("html").removeClass("no-scroll");
    return false;
  });
  $('.modal form input[type="submit"]').on("click", function (event) {
    if (event) event.preventDefault();
    var form = $(this).parents("form");
    $.ajax({
      type: form.attr("method"),
      url: form.attr("action"),
      data: form.serialize(),
      cache: false,
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      error: (error) => {
        console.log("Error", error);
      },
      success: (data) => {
        console.log(data);
        if (data.result != "success") {
          if (data.result === "error") {
            form.find("#mce-responses #mce-error-response").text(data.msg.replace("0 - ", "")).css({ display: "block" });
          }
        } else {
          form.parents(".modal-wrap").addClass("ordered");
        }
      },
    });
  });

  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape") {
      if (!$(".modal-wrap.show").hasClass("confirm-age-wrap")) {
        $(".modal-wrap.show").removeClass("show");
        $("html").removeClass("no-scroll");
      }
    }
  });

  $(".social-links a").click(function () {
    var url = $(this).attr("href");
    window.open(url, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
    return false;
  });

  const mediaSlider = $(".media-slider").flickity({
    contain: true,
    setGallerySize: false,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
  });
  const mediaSliderNav = $(".media-slider-nav").flickity({
    contain: true,
    asNavFor: ".media-slider",
    pageDots: false,
    prevNextButtons: false,
    cellAlign: "right",
    draggable: false,
  });
  var flkty = mediaSlider.data("flickity");

  function updateStatus() {
    var cellNumber = flkty.selectedIndex + 1;
    $(".media-slider-meta .current").text(cellNumber);
    $(".media-slider-meta .total").text(flkty.slides.length);
  }
  updateStatus();
  mediaSlider.on("select.flickity", updateStatus);

  $(".press-media-card").click(function () {
    const index = $(this).attr("data-image") - 1;
    console.log(index);
    mediaSlider.flickity("select", index, false, true);
    return false;
  });

  Splitting();
  let tempCount = 1;
  window.addEventListener("scroll", () => {
    tempCount = 1;
  });
  let colObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!entry.target.classList.contains("has-been-seen")) {
            entry.target.style.setProperty("--i", tempCount);
            tempCount++;
          }
          entry.target.classList.add("has-been-seen");
          entry.target.classList.add("active");
          setTimeout(function () {
            entry.target.classList.add("animation-complete");
          }, tempCount * 80 + 1000);
        } else {
          entry.target.classList.remove("active");
        }
      });
    },
    { rootMargin: "0px 0px -5% 0px" }
  );
  const cols = document.querySelectorAll(".appear");
  cols.forEach((col) => {
    colObserver.observe(col);
  });
  const diseaseIlustrations = document.querySelectorAll(".disease-illustration");
  diseaseIlustrations.forEach((diseaseIlustration) => {
    colObserver.observe(diseaseIlustration);
  });

  // let bottleCanvas = document.getElementById("bottle-sequence");
  // // const context = bottleCanvas.getContext("2d");
  // // (bottleCanvas.width = bottleCanvas.getBoundingClientRect().width), (bottleCanvas.height = bottleCanvas.getBoundingClientRect().height);
  // const e = 1920,
  //   i = 1280,
  //   n = 71;
  // const o = [],
  //   r = { frame: 0 };
  // for (let t = 0; t < n; t++) {
  //   const e = new Image();
  //   (e.src = "sequence/bottle" + t.toString().padStart(2, "0") + ".jpg"), o.push(e);
  // }
  // console.log(o);
  // function f() {
  //   context.clearRect(0, 0, bottleCanvas.width, bottleCanvas.height);
  //   var n = Math.max(bottleCanvas.width / e, bottleCanvas.height / i),
  //     a = bottleCanvas.width / 2 - (e / 2) * n,
  //     s = bottleCanvas.height / 2 - (i / 2) * n;
  //   context.drawImage(o[r.frame], a, s, e * n, i * n);
  // }
  // gsap.to(r, { frame: n - 1, snap: "frame", scrollTrigger: { trigger: bottleCanvas, scrub: true, pin: bottleCanvas, start: "top top", end: "+=100%", anticipatePin: 1 }, onUpdate: swapImage }), (o[0].onload = swapImage);
  // const a = gsap.timeline({ scrollTrigger: { trigger: "#bottle-sequence", scrub: true, pin: !0, start: "top top", end: "+=100%" } });

  let frameCount;
  let urls;
  let imgSrcWidth;
  let imgSrcHeight;
  if ($(window).width() < 768) {
    frameCount = 82;
    urls = new Array(frameCount).fill().map((o, i) => `sequence/bottle-mobile${i.toString().padStart(2, "0")}.jpg`);
    imgSrcWidth = 430;
    imgSrcHeight = 932;
  } else {
    frameCount = 124;
    urls = new Array(frameCount).fill().map((o, i) => `sequence/bottle${i.toString().padStart(3, "0")}.jpg`);
    imgSrcWidth = 1920;
    imgSrcHeight = 1080;
  }
  const canvas = document.querySelector("#bottle-sequence");
  const parent = $(canvas).parents(".scroll-animation");
  const startWidth = canvas.getBoundingClientRect().width;
  const startHeight = canvas.getBoundingClientRect().height;
  canvas.width = startWidth;
  canvas.height = startHeight;
  canvas.style.width = startWidth + "px";
  canvas.style.height = startHeight + "px";
  imageSequence({
    urls,
    canvas: "#bottle-sequence",
    scrollTrigger: {
      start: $(canvas).offset().top - $(window).height() * 0.5,
      end: "+=100%",
      scrub: true,
      invalidateOnRefresh: true,
    },
  });
  function imageSequence(config) {
    let playhead = { frame: 0 },
      ctx = gsap.utils.toArray(config.canvas)[0].getContext("2d"),
      onUpdate = config.onUpdate,
      images,
      updateImage = function () {
        // ctx.drawImage(images[Math.round(playhead.frame)], 0, 0);
        var scaleX = ctx.canvas.width / imgSrcWidth;
        var scaleY = ctx.canvas.height / imgSrcHeight;
        var scale = Math.max(scaleX, scaleY);
        var centerX = ctx.canvas.width / 2;
        var centerY = ctx.canvas.height / 2;
        var width = imgSrcWidth * scale;
        var height = imgSrcHeight * scale;
        var x = centerX - width / 2;
        var y = centerY - height / 2;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(images[Math.round(playhead.frame)], x, y, width, height);
        onUpdate && onUpdate.call(this);
      };
    images = config.urls.map((url, i) => {
      let img = new Image();
      img.src = url;
      i || (img.onload = updateImage);
      return img;
    });
    const resize = () => {
      const newWidth = parent[0].getBoundingClientRect().width;
      const newHeight = parent[0].getBoundingClientRect().height;
      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.width = newWidth + "px";
      canvas.style.height = newHeight + "px";
      updateImage();
    };
    window.addEventListener("resize", () => {
      resize();
    });
    return gsap.to(playhead, {
      frame: images.length - 1,
      ease: "none",
      onUpdate: updateImage,
      scrollTrigger: config.scrollTrigger,
    });
  }

  $(".slider").flickity({
    cellAlign: "left",
    contain: true,
    prevNextButtons: false,
    wrapAround: true,
  });

  $(".load-more-news").click(function () {
    $(this).parents("section").find("article.hide").removeClass("hide");
    $(this).remove();
    return false;
  });

  // Fancybox.bind("[data-fancybox]", {
  //   idle: false,
  //   animated: false,
  //   contentClick: false,
  //   Images: {
  //     zoom: false,
  //     zoomOpacity: false,
  //   },
  //   showClass: false,
  //   hideClass: false,
  //   wheel: false,
  //   Toolbar: {
  //     absolute: false,
  //     display: {
  //       left: ["infobar"],
  //       middle: [],
  //       right: ["close"],
  //     },
  //     items: {
  //       infobar: {
  //         tpl: '<div class="fancybox__infobar" tabindex="-1"><p class="color-white">Press material</p><span data-fancybox-current-index></span>/<span data-fancybox-count></span></div>',
  //       },
  //     },
  //   },
  //   Thumbs: {
  //     type: "classic",
  //   },
  //   Carousel: {
  //     transition: false,
  //     friction: 0,
  //     Dots: false,
  //     center: false,
  //     Navigation: false,
  //   },
  // });

  const isInViewport = (element) => {
    var rect = element.getBoundingClientRect();
    var position = rect.top / window.innerHeight;
    if (position >= 0 && position <= 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkTargetPosition = (element) => {
    var rect = element.getBoundingClientRect();
    var fromTop = rect.top;
    var fraction = (rect.top - window.innerHeight / 2) / (window.innerHeight / 2);
    const min = -1;
    const max = 1;
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    var percentage = fraction * 100;
    const viewPortYPos = clamp(fraction.toFixed(2), min, max);
    element.style.setProperty("--viewport-y", viewPortYPos * -1);
    if (fromTop < 0) {
      element.classList.add("reached-top");
    } else {
      element.classList.remove("reached-top");
    }
    // console.log("target scroll:", fromTop, "px from top.", fraction, "/", percentage, "%");
  };

  const allScrolls = document.querySelectorAll("[data-y]");
  const checkScrollPositions = () => {
    allScrolls.forEach((scroll) => {
      // if (isInViewport(scroll)) {
      checkTargetPosition(scroll);
      // }
    });
  };
  checkScrollPositions();
  window.addEventListener("scroll", () => {
    checkScrollPositions();
  });

  $(".full-width-video-wrap").each(function () {
    const wrap = $(this);
    const video = $(this).find(".full-width-video video")[0];
    const button = $(this).find(".play-pause-movie");
    const playVideo = () => {
      wrap.addClass("playing");
      video.play();
    };
    const pauseVideo = () => {
      wrap.removeClass("playing");
      video.pause();
    };
    const togglePlayState = () => {
      if (wrap.hasClass("playing")) {
        pauseVideo();
        button.html('<svg class="icon icon-play"><use xlink:href="#icon-play"></use></svg>');
      } else {
        playVideo();
        button.html('<svg class="icon icon-pause"><use xlink:href="#icon-pause"></use></svg>');
      }
    };
    button.click(function () {
      togglePlayState();
      return false;
    });
    wrap.find(".video-thumbnail .bg").click(function () {
      togglePlayState();
    });
  });
});
